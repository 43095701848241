<template>
    <a-spin :spinning="loading">
        <a-table
            :columns="colums"
            :dataSource="data"
            bordered
            :pagination="false">
            <template slot="title">
                <a-button icon="plus" type="primary" @click="$refs.userAccountForm.show(null)">添加用户</a-button>
            </template>
            <template slot="head" slot-scope="text">
                <a-avatar size="large" :src="text || defaultHead" />
            </template>
            <template slot="roleNames" slot-scope="text, record">
                <a-tag v-for="(item, index) in record.roles" :key="index">{{ item.roleName }}</a-tag>
            </template>
            <template slot="action" slot-scope="text, record">
                <a-button size="small" type="primary" class="action" @click="$refs.userAccountForm.show(record)">修改</a-button>
                <a-popconfirm
                    title="确认删除该用户？"
                    ok-text="确认"
                    cancel-text="取消"
                    @confirm="del(record)">
                    <a-button size="small" type="info" class="action">删除</a-button>
                </a-popconfirm>
                <a-button size="small" class="action" @click="$refs.resetUserPwd.show(record)">重置密码</a-button>
            </template>
        </a-table>
        <a-pagination
            showQuickJumper
            style="margin-top:10px;"
            :defaultCurrent="1"
            :total="total"
            :pageSize="params.pageSize"
            @change="pageChange"/>
        <user-account-form ref="userAccountForm" @success="pageQuery()"></user-account-form>
        <reset-user-pwd ref="resetUserPwd"></reset-user-pwd>
    </a-spin>
</template>
<script>
import UserAccountForm from './modules/UserAccountForm'
import ResetUserPwd from "@/views/userAccount/modules/ResetUserPwd"
import { mapState } from 'vuex'
const colums = [
    { title: '头像', dataIndex: 'head', width: '100px', scopedSlots: { customRender: 'head' }},
    { title: '昵称', dataIndex: 'name'},
    { title: '登录账号', dataIndex: 'account'},
    { title: '角色', dataIndex: 'roleNames', scopedSlots: { customRender: 'roleNames' }},
    { title: '操作', scopedSlots: { customRender: 'action' } }
]
export default {
    components: {
        UserAccountForm,
        ResetUserPwd
    },
    data () {
        return {
            loading: false,
            defaultHead: require('@/assets/default_head.png'),
            colums,
            data: [],
            total: 0,
            params: {
                pageNum: 1,
                pageSize: 20
            }
        }
    },
    computed: {
        ...mapState({
        })
    },
    mounted () {
        this.pageQuery()
    },
    methods: {
        pageQuery () {
          this.loading = true
          this.$api.userAccount.page(this.params.pageNum, this.params.pageSize).then(res => {
              if (res.status === 0) {
                  this.data = res.data.list
                  this.total = res.data.total
              } else {
                  this.$message.error(res.msg)
              }
          }).catch(err => {
              this.$message.error(err)
          }).finally(_ => {
              this.loading = false
          })
        },
        update (record) {},
        del (record) {
            this.$api.userAccount.del(record.id).then(res => {
                if (res.status === 0) {
                    this.$message.success('成功')
                    this.pageQuery()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        pageChange (pageNumber) {
            this.params.pageNum = pageNumber
            this.pageQuery()
        }
    }
}
</script>
<style scoped>
    .action {
        margin-right: 10px;
    }
</style>
